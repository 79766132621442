export default {
	init() {
		console.log('challenges');

		// Filters contributi
		let filterForm = document.getElementById('filterForm');
		let filterSelects = document.querySelectorAll('#filterForm select');
		let filterResetBtn = document.getElementById('resetBtn');

		if (filterResetBtn) {
			filterResetBtn.addEventListener('click', function () {
				if (filterSelects) {
					filterSelects.forEach(function (select) {
						select.value = '';
					});
				}
				if (filterForm) filterForm.submit();
			});
		}

		//Load more contributi
		if (filterForm) {
			jQuery(document).ready(function ($) {
				let currentPage = 1;

				let loadMoreBtn = $('#loadMoreSubs');
				let list = $('#subsList');
				let challengeSlug = document.querySelectorAll(
					'#filterForm select[name="challengeSlug"]'
				)[0].value;
				let prizes = document.querySelectorAll(
					'#filterForm select[name="prizes"]'
				)[0].value;

				let maxPages = loadMoreBtn.attr('data-max-pages');

				loadMoreBtn.bind('click', function (e) {
					e.preventDefault();
					currentPage++;

					loadMoreBtn.addClass('isLoading');

					$.ajax({
						type: 'POST',
						url: '/wp-admin/admin-ajax.php',
						data: {
							action: 'submissions_load_more',
							paged: currentPage,
							challengeSlug: challengeSlug,
							prizes: prizes,
						},
						success: function (res) {
							list.append(res);
							if (currentPage >= maxPages) {
								loadMoreBtn.hide();
							} else {
								loadMoreBtn.removeClass('isLoading');
							}
						},
						fail: function (err) {
							console.log('There was an error: ' + err);
							loadMoreBtn.removeClass('isLoading');
						},
					});
				});
			});
		}
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
