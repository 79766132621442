import Typed from 'typed.js';

export default {
	init() {
		console.log('landing');

		var typed = new Typed('#typed', {
			stringsElement: '#typed-strings',
			loop: true,
			backSpeed: 50,
			typeSpeed: 100,
		});

		var btnFixed = document.getElementById('subBtn');
		if (btnFixed) {
			window.onscroll = function (e) {
				if (scrollY > 50) {
					btnFixed.classList.add('active');
				}
				if (scrollY < 50) {
					btnFixed.classList.remove('active');
				}
			};
		}
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
