import UIkit from 'uikit'

export default {
  init() {
    const btn_go_to_lesson = document.getElementById('go_to_lesson')
    btn_go_to_lesson &&
      btn_go_to_lesson.addEventListener('click', (event) => {
        // event.preventDefault()
        const lesson_tabs = document.getElementById('lesson_tabs')
        UIkit.tab(lesson_tabs).show(1)
      })

    const btn_go_to_challenge = document.getElementById('go_to_challenge')
    btn_go_to_challenge &&
      btn_go_to_challenge.addEventListener('click', (event) => {
        // event.preventDefault()
        const lesson_tabs = document.getElementById('lesson_tabs')
        UIkit.tab(lesson_tabs).show(2)
      })
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
}
