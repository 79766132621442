import UIkit from 'uikit'

jQuery(document).ready(function ($) {
  const deleteModal = document.getElementById('modal-delete-assessment')
  const editModal = document.getElementById('modal-edit-assessment')

  // Perform AJAX edit
  $('.edit_assessment').on('click', function (e) {
    window.assessmentID = $(this).attr('data-assessment')
    const label = $(this).attr('data-label')
    console.log(label)
    if (editModal) {
      const inputEdit = document.getElementById('edit-label')
      inputEdit.value = label
      UIkit.modal(editModal).show()
    }
  })

  $('#edit_assessment_confirm').on('click', function (e) {
    const newLabel = document.getElementById('edit-label')

    $('.spinner_box').show()
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: ajax_login_object.ajaxurl,
      data: {
        action: 'edit_assessment', //calls wp_ajax_nopriv_edit_assessment
        assessment_id: assessmentID,
        new_label: newLabel.value,
      },
      success: function (data) {
        $('.spinner_box').hide()
        if (data && data.update_status == true) {
          var icon = '<span uk-icon="icon: check"></span> '

          const assessment = document.getElementById(
            'assessment_' + data.assessment_id
          )
          const assessment_label = assessment.querySelector('.assessment_label')
          assessment_label.innerHTML = newLabel.value

          const edit_assessment_label =
            assessment.querySelector('.edit_assessment')
          edit_assessment_label.dataset.label = newLabel.value
        } else {
          var icon = '<span uk-icon="icon: close"></span> '
        }
        UIkit.notification(icon + data.message, {
          pos: 'top-right',
        })
      },
      error(error) {
        var icon = '<span uk-icon="icon: close"></span> '
        UIkit.notification(icon + error, {
          pos: 'top-right',
        })
      },
      complete: function () {
        $('.spinner_box').hide()
        UIkit.modal(editModal).hide()
      },
    })
    e.preventDefault()
  })

  // Perform AJAX delete
  $('.delete_assessment').on('click', function (e) {
    window.assessmentID = $(this).attr('data-assessment')
    if (deleteModal) {
      UIkit.modal(deleteModal).show()
    }
  })

  $('#delete_assessment_confirm').on('click', function (e) {
    $('.spinner_box').show()
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: ajax_login_object.ajaxurl,
      data: {
        action: 'archive_assessment', //calls wp_ajax_nopriv_archive_assessment
        assessment_id: assessmentID,
      },
      success: function (data) {
        $('.spinner_box').hide()
        console.log(data)
        if (data && data.update_status == true) {
          var icon = '<span uk-icon="icon: check"></span> '

          // Remove element form the dom
          $('#assessment_' + data.assessment_id).remove()
          var total = $('.num_assessments').html()
          $('.num_assessments').html(Number(total) - 1)
        } else {
          var icon = '<span uk-icon="icon: close"></span> '
        }

        UIkit.notification(icon + data.message, {
          pos: 'top-right',
        })
      },
      error(error) {
        console.log(error)
        var icon = '<span uk-icon="icon: close"></span> '

        UIkit.notification(icon + error, {
          pos: 'top-right',
        })
      },
      complete: function () {
        $('.spinner_box').hide()
        UIkit.modal(deleteModal).hide()
      },
    })
    e.preventDefault()
  })
})
