import Typed from 'typed.js';
import inView from 'in-view';
import '@lottiefiles/lottie-player';

export default {
	init() {
		console.log('home');

		new Typed('#typed', {
			stringsElement: '#typed-strings',
			loop: true,
			backSpeed: 50,
			typeSpeed: 100,
		});

		inView('.where_start_section').once('enter', startAnimation);

		function startAnimation() {
			const text_list = document.getElementById('text_list').children;
			let index = 1;

			console.log('aniamtion');

			setInterval(() => {
				Array.from(text_list).forEach(function (element) {
					element.classList.remove('active');
				});
				text_list[index].classList.add('active');
				index = index < text_list.length - 1 ? index + 1 : 0;
			}, 4000);

			setTimeout(() => {
				lottiePlayer.play();
			}, 200);
		}

		const lottiePlayer = document.querySelector('lottie-player');

		//News filter
		jQuery(document).ready(function ($) {
			let loadMoreBtn = $('#loadMoreBtn'),
				cases = $('#caseGrid'),
				numMax = $('#numOfPosts').val(),
				paged = 1;

			loadMoreBtn.bind('click', function (e) {
				e.preventDefault();
				paged++;
				loadPosts();
			});

			function loadPosts() {
				cases.addClass('loading');
				loadMoreBtn.css('pointer-events', 'none');

				$.ajax({
					type: 'POST',
					url: '/wp-admin/admin-ajax.php',
					dataType: 'json',
					data: {
						action: 'load_cases',
						postsPerPage: numMax,
						paged: paged,
					},
					success: function (res) {
						if (paged == 1) {
							cases.html(res.posts);
						} else {
							cases.append(res.posts);
						}
						if (res.maxPages > paged && loadMoreBtn.length) {
							loadMoreBtn.show();
							loadMoreBtn.css('pointer-events', 'auto');
						} else {
							loadMoreBtn.hide();
						}
						cases.removeClass('loading');
					},
					fail: function (err) {
						console.log('There was an error: ' + err);
						cases.removeClass('loading');
					},
				});
			}
		});
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
