import UIkit from 'uikit';


// Open modal from URL
const userLoggedIn = document.body.classList.contains('logged-in');
if(!userLoggedIn){
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  // const registration = urlParams.get('registration')
  // const registrationModal = document.getElementById('registration-modal')
  // if(registration){
  //   UIkit.modal(registrationModal).show();
  // }

  const login = urlParams.get('login')
  const loginModal = document.getElementById('login-modal')
  if(login){
    UIkit.modal(loginModal).show();
  }

  // Closing validation modal
  var close_btn = document.querySelector('.check-email-verification .close');
  if (close_btn){
    close_btn.addEventListener('click', function(){
      console.log('close modal');
      UIkit.modal(loginModal).show();
    });
  }

  const private_page = document.body.classList.contains('private_page')
  if(private_page){
    UIkit.modal(loginModal).show();
  }
  
  // Single news newsletter modal
  const sinle_post_page = document.body.classList.contains('single-post')
  if(sinle_post_page){
    const nlModal = document.getElementById('newsletter_single_news_modal')
    const newlsetterBlock = nlModal.querySelector('.newsletter_section')
    newlsetterBlock.classList.add('modal_newsletter')
    const modalAlreadyOpened = sessionStorage.getItem('nlModalShown')
    console.log(modalAlreadyOpened)
    if (modalAlreadyOpened === null) {
      setTimeout(function() {
        UIkit.modal(nlModal).show();
      }, 7000)
      sessionStorage.setItem('nlModalShown', 1)
    }
  
  }
}




// Share link
var linkBtns = document.getElementsByClassName('share-link');

var setShareLink = function() {
  var link = this.getAttribute('data-link');
  var title = this.getAttribute('data-title');
  var big_link = document.getElementById('big_link');
  var modal_title = document.getElementById('modal_title');

  link = link.replace('https://', '');
  link = link.replace('http://', '');
  big_link.value = link;

  if (title) {
    modal_title.innerHTML = title;
  }
};

for (var i = 0; i < linkBtns.length; i++) {
  var link = linkBtns[i].dataset.link;
  linkBtns[i].addEventListener('click', setShareLink, false);
}

// Copy link
var modalSharing = document.getElementById('link-sharing-modal');
var copyText = document.getElementById("big_link");
var copyBtn = document.getElementById('copy_button_modal');
copyBtn && copyBtn.addEventListener('click', copyToClipboard);

function copyToClipboard() {
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */
  /* Copy the text inside the text field */
  document.execCommand("copy");
  // copyBtn.__uikit__.tooltip.title = 'Link copiato';
  modalSharing.classList.add('copied')
  UIkit.notification('<span uk-icon="icon: check"></span> Link copiato',
    {
      pos: 'top-right'
    }
  );
}


// Reset sharing link modal classes
UIkit.util.on('#link-sharing-modal', 'hidden', function () {
  this.classList.remove('copied')
});