
jQuery(document).ready(function($) {

  $('.download_csv').on('click', function(e){
      $('.loader').addClass('waiting');
      $('.loader').removeClass('hide');
      console.log('csv start');
      var assessment_id = $(this).attr('data-assessment-id');
      var teacher_name = $(this).attr('data-teacher-name');
      var lesson = $(this).attr('data-lesson');
      var assessment_label = $(this).attr('data-assessment-label');

      $.ajax({
          type: 'POST',
          // headers:{ 
          //   'Accept': 'text/csv',
          //   'Content-Type': 'text/csv' 
          // },
          url: ajax_login_object.ajaxurl,
          data: {
            'action': 'download_csv', //calls wp_ajax_nopriv_download_csv
            'assessment_id': assessment_id,
            'teacher_name': teacher_name,
            'lesson': lesson,
            'assessment_label': assessment_label,
          },
          success: function(data){
            console.log('csv success');
            $('.loader').addClass('hide');
            $('.loader').removeClass('waiting');
            /*
            * Make CSV downloadable
            */
            var downloadLink = document.createElement("a");
            var fileData = ['\ufeff'+data];

            var blobObject = new Blob(fileData,{
                type: "text/csv;charset=utf-8;"
              });

            var url = URL.createObjectURL(blobObject);
            downloadLink.href = url;
            
            // File format
            // [YYYYMMDD] - [Nome docente] - [Titolo lezione] - [Titolo assessment].csv.
            var rightNow = new Date();
            var formatted_date = rightNow.toISOString().slice(0,10).replace(/-/g,"");
            downloadLink.download = formatted_date+'-'+teacher_name+'-'+lesson+'-'+assessment_label+'.csv';

            /*
              * Actually download CSV
              */
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          },
          error: function(data){
            $('.loader').addClass('hide');
            $('.loader').addClass('waiting');
            console.log('error')
            console.log(data);
          }
      });
      e.preventDefault();
  });

});
