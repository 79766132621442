import jQuery from 'jquery';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

import Router from './js/util/Router';
import common from './js/routes/common';
import home from './js/routes/home';
import pageTemplateHome from './js/routes/homeEducators';
import pageTemplateTplRegistrationLanding from './js/routes/landing';
import singleLesson from './js/routes/lesson';
import pageTemplateTplAccountEdit from './js/routes/account';
import pageTemplateTplLessons from './js/routes/lessons';
import pageTemplateTplPartnerships from './js/routes/partnerships';
import singlePartnership from './js/routes/partnership';
import singleChallenge from './js/routes/challenge';
import pageTemplateTplRegistration from './js/routes/registration';
import pageTemplateTplCalendar from './js/routes/calendar';
import pageTemplateTplChallenges from './js/routes/challenges';

// loads the Icon plugin
UIkit.use(Icons);

import './scss/uikit-main.scss';
import './styl/application.styl';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
	common,
	home,
	pageTemplateHome,
	pageTemplateTplRegistrationLanding,
	pageTemplateTplRegistration,
	singleLesson,
	pageTemplateTplLessons,
	pageTemplateTplAccountEdit,
	pageTemplateTplPartnerships,
	singlePartnership,
	singleChallenge,
	pageTemplateTplCalendar,
	pageTemplateTplChallenges,
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
