import UIkit from 'uikit';

// Send link by email
const sendBtn = document.getElementById('share_email');
const emailsField = document.getElementById("emails_field");

emailsField && emailsField.addEventListener('keyup', (event) => {
  const field_value = event.target.value.replace(/\s/g, '')
  
  var encoded_body = encodeURIComponent('Registrati a questo link: ' + copyText.value);
  var encoded_subject = encodeURIComponent('Registrati su Open the Box!');
  
  const sendBtn_link = 'mailto:' + field_value + '?body=' + encoded_body + '&subject=' + encoded_subject + '"';
  sendBtn.href = sendBtn_link
})


// Copy link
const copyText = document.getElementById("link_to_copy");
const copyBtn = document.getElementById('copy_button');
copyBtn && copyBtn.addEventListener('click', copyToClipboard);

function copyToClipboard() {
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */
  /* Copy the text inside the text field */
  document.execCommand("copy");
  UIkit.notification('<span uk-icon="icon: check"></span> Link copiato',
    {
      pos: 'top-right'
    }
  );
}