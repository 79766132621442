export default {
	init() {
		console.log('partnerships');

		jQuery(document).ready(function ($) {
			var $horizontal = $('#sliderLeft');
			var $horizontal2 = $('#sliderRight');

			var offsetStart = 0, //50
				offsetEnd = 0, //300
				translate = 180; //(1980 - window.innerWidth)

			$(window).scroll(function () {
				var rect = $horizontal[0].getBoundingClientRect();
				var percentage =
					100 -
					((rect.top - offsetStart) /
						(window.innerHeight - offsetEnd)) *
						100;
				var position = (percentage * translate) / 100;

				var rect2 = $horizontal2[0].getBoundingClientRect();
				var percentage2 =
					100 -
					((rect2.top - offsetStart) /
						(window.innerHeight - offsetEnd)) *
						100;
				var position2 = (percentage2 * translate) / 100;

				if (percentage >= 0 && percentage2 <= 200) {
					$horizontal.css({
						'left': '-' + position + 'px',
					});
				}

				if (percentage >= 0 && percentage2 <= 200) {
					$horizontal2.css({
						'right': '-' + position2 + 'px',
					});
				}
			});
		});
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
