export default {
  init() {
    console.log("account");
    const currentRadioSelected = document.querySelector(
      'input[name="role"]:checked'
    );
    const pathBasedFields = document.getElementById("path_based_fields");
    const teacherFields = document.getElementById("teacher_fields");
    const notTeacherFields = document.getElementById("not_teacher_fields");
    if (currentRadioSelected) {
      if (currentRadioSelected.value === "1") {
        pathBasedFields.innerHTML = teacherFields.innerHTML;
      } else {
        pathBasedFields.innerHTML = notTeacherFields.innerHTML;
      }
    }
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
