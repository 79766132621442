import UIkit from 'uikit'

export default {
  init() {
    console.log('lessons!!')
    // Switcher
    const switcher = document.getElementById('lessons-switcher')
    const switcherItems = switcher && switcher.querySelectorAll('li a')

    // get anchor from url
    const anchor = window.location.hash
    console.log(anchor)

    if (anchor) {
      // Get index of list by anchor
      const index = Array.prototype.indexOf.call(
        switcherItems,
        switcher.querySelector(`a[href="${anchor}"]`)
      )
      // Open tab based on index
      UIkit.switcher(switcher).show(index)
    }

    switcherItems.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault()
        // get data-tab value
        const tab = e.target.getAttribute('data-tab')
        if (tab === 'lessons') {
          // show lessons
          document.getElementById('switch_section').classList.add('lessons')
        } else {
          document.getElementById('switch_section').classList.remove('lessons')
        }

        const lessonAnchor = e.target.getAttribute('href')
        // add anchor to url
        window.history.pushState({}, '', lessonAnchor)
        e.target.classList.add('active')
      })
    })
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
}
